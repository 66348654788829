'use client'

import type { AnchorProps } from ".";

import { useEffect, useRef } from "react";

import useQueryParams from "@hooks/useQueryParams";

const Anchor = ({ title, param }: AnchorProps) => {
    const { setQueryParam } = useQueryParams();

    const ref = useRef(null);

    useEffect(() => {
        if (!ref?.current) return;

        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                setQueryParam(param, title);
            }
        }, {
            rootMargin: '0px 0px -85% 0px',
        });

        observer.observe(ref.current);
    });

    return (
        <h2><a ref={ref} id={title} key={'anchor_' + title}>{title}</a></h2>
    )
}

export default Anchor
