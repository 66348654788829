'use client';

import classNames from "classnames";
import Link from "@components/atoms/link";

const WikiScrollLink = ({ children, last, ...props }: any) => {

    const handleScroll = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        //remove everything before the hash
        const targetId = e.currentTarget.href.replace(/.*\#/, "");
        const elem = document.getElementById(targetId);
        window.scrollTo({
            top: elem?.getBoundingClientRect().top,
            behavior: "smooth",
        });
    };

    return (
        <Link className={classNames('d-inline-block  fw-bold  text-nowrap  text-decoration-none', { 'me-2': !last })} onClick={handleScroll} {...props}>
            {children}
        </Link>
    );
};

export default WikiScrollLink
