import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/react-bootstrap/esm/Col.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/react-bootstrap/esm/Container.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/react-bootstrap/esm/Row.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/atoms/anchor-w-param/Anchor.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/atoms/video/Video.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/atoms/wiki-scroll-link/WikiScrollLink.tsx");
